<template>
  <div style="padding-left: 100px; padding-right: 100px;">
	<side-bar-admin/>
  <v-card style="margin: 150px 0px;">
    <v-card-title style="color: var(--vertfonce);">
      Liste des utilisateurs
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table v-if="show == 1"
		:headers="headers"
		:items="users"
		:items-per-page="5"
		class="elevation-1"
		>
		<template v-slot:item.date_creation="{ item }">
              {{ formatDate(item.date_creation) }}
            </template>
		<!-- ... autres slots ... -->
		<template v-slot:item.action="{ item }">
			<button class="button-suppress" @click="email = item.email; dialog2 = true; ">supprimer</button>
		</template>
		<template v-slot:item.tag_attributed="{ item }">
			<v-select
			:items="item.tag_attributed"
			label="Tags"
			v-model="tag_selected"
			chips
			small-chips
			dense
			@change="email = item.email; dialog1 = true"
			></v-select>
		</template>
		</v-data-table>
  </v-card>
  <v-dialog v-model="dialog1" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <v-card-text>
          Êtes vous sûr de vouloir déposséder l'utilisateur: {{ email }} du tag {{ tag_selected}} ?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="dialog1 = false">Cancel</v-btn>
          <v-btn color="success" text @click="removeAttribution">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-dialog v-model="dialog2" max-width="290">
			<v-card>
				<v-card-title class="headline">Confirmation</v-card-title>
				<v-card-text>
					Êtes-vous sûr de vouloir supprimer l'utilisateur {{ email}} ? Son compte sera détruit et perdra tout son matériel attribué.
				</v-card-text>
				<v-card-actions>
					<v-btn color="blue darken-1" text @click="dialog2 = false">Non</v-btn>
					<v-btn color="blue darken-1" text @click="deleteUser">Oui</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
  </div>
</template>

<script>
import SideBarAdmin from '../components/SideBarAdmin.vue';
import moment from 'moment';
moment.locale('fr');
	export default {
  components: { SideBarAdmin },
		
		data(){
			return {
				show: 0,
				headers: [
					{
						text: 'user_id',
						align: 'start',
						value: 'user_id',
					},
					{ text: 'username', value: 'username'},
					{ text: 'Supprimer', value: 'action'},
					{ text: 'date de création', value: 'date_creation'},
					{ text: 'email', value: 'email'},
					{ text: 'nom', value: 'nom'},
					{ text: 'prénom', value: 'prenom'},
					{ text: 'société', value: 'societe'},
					{ text: 'Tags attributed', value: 'tag_attributed'},
					{ text: 'admin', value: 'admin'},
				],
				users: [
					{
						user_id: 1,
						username: 'JohnDoe',
						email: 'john@example.com',
						nom: 'Doe',
						prenom: 'John',
						societe: 'Example Corp',
						tag_attributed: ['tag1', 'tag2', 'tag3'],
						admin: true,
					},
					// ... autres utilisateurs ...
				],
				email: '',
				dialog1 : false,
				dialog2 : false,
				tag_selected : '',
			}
		},

    mounted(){
			this.GetUsers();
		},

	methods: {
		async removeAttribution(){
			console.log(this.tag_selected,  this.email);
			const url = this.$api.getRESTApiUri() + `/admin/supress-tag-attribution`;
			try {
				const response = await fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						tag_id: this.tag_selected,
						email: this.email
					}),
				});
				if (response.ok) {
					// La suppression a réussi, vous pouvez maintenant mettre à jour le tableau item.tag_attributed.

					// Trouvez l'utilisateur dans la liste des utilisateurs.
					const user = this.users.find((user) => user.email === this.email);

					// Mettez à jour le tableau item.tag_attributed.
					user.tag_attributed = user.tag_attributed.filter((tag) => tag !== this.tag_selected);

					// Fermez la boîte de dialogue.
					this.dialog1 = false;
				} else {
					console.error('Erreur lors de la désattribution du tag:', response.status);
				}
			} catch (error) {
				console.error("Erreur lors de la désatribution du tag:", error);
			}
			this.dialog1 = false ;
		},
		async GetUsers(){
			const url= this.$api.getRESTApiUri() + `/get-users`;
			console.log(url)
			fetch(url)
			.then(res => res.text())
			.then((result) => {
				this.users = JSON.parse(result)
				this.show = 1;
			})
			.catch((error) => {
				console.log(error)
			});
		},
		async deleteUser() {
			const email = this.email;
			console.log(email);
			const url = this.$api.getRESTApiUri() + `/supress-user/${email}`;

			try {
				const response = await fetch(url, {
					method: 'PUT',
				});

				if (response.ok) {
					console.log('User deleted successfully');

					// Après la suppression réussie, filtrez l'utilisateur supprimé du tableau users.
					this.users = this.users.filter((user) => user.email !== email);

					// Fermez la boîte de dialogue.
					this.dialog2 = false;
				} else {
					console.error('Error:', response.status);
					// Gérez l'erreur si nécessaire
				}
			} catch (error) {
				console.error('Error:', error);
				// Gérez l'erreur si nécessaire
			}
		},
		formatDate(date) {
			return moment(date).format('LL, LT');
		},
	}
	}
</script>


<style scoped>
.btn-delete{
  background-color: indianred !important;
  color: aliceblue;
  padding: 5px !important;
  border-radius: 10%;
  font-size: 15px;
}

.button-suppress{
	justify-content: center;
	background-color: firebrick;
	border: solid 1px firebrick;
	padding: 4px 8px;
	border-radius: 5px;
	color: white;
	transition: 0.5s;
}

.button-suppress:hover{
	background-color: white;
	border: solid 1px firebrick;
	color: firebrick;
	transition: 0.5s;
}
</style>